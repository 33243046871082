<div id="products">
    <div class="product-item"
        *ngFor="let product of products; let index  = index;"
        [ngClass]="showMore ? showMoreString : index > 1 ? showMoreString : 'show'">
        <a href="{{product.text}}" class="product-link">
            <img src="{{baseUrl + product.image.image}}" alt="{{product.text_name}}" class="product-img">
        </a>
    </div>
</div>
<div id="mobile-btn-container">
    <span id="mobile-text"> Mostrar {{!showMore ? 'mais' : 'menos'}}</span>
    <button id="mobile-btn">
        <img src="assets/seta-footer.svg" alt="" id="mobile-img" (click)="toggle()"
        [ngClass]="showMoreString">
    </button>
</div>