import { Component, Input, OnInit } from '@angular/core';
import { Report } from 'src/app/models/report.model';
import { ApiService } from 'src/app/services/api.service';
import { Text } from 'src/app/models/text.model';


@Component({
  selector: 'ipe-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  constructor(
    private api: ApiService,
  ) { }

  @Input() reportTexts: Text[] = [];

  reports!: Report[];
  presentations! : Report[];
  apiUrl! : string;

  ngOnInit(): void {
    this.api.getReports().subscribe((apiReports) => {
      this.reports = apiReports.reports;
      this.presentations = apiReports.presentations;
    })

    this.apiUrl = this.api.getApiUrl();

  }

}
