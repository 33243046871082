<div class="presentation-container">
    <div class="presentation-text">
        <h1>{{this.title}}</h1>
        <p [innerHTML]="subtitle"></p>
    </div>
    <div class="img-container" *ngIf="imgPresentation">
        <img src="{{this.imgPresentation}}" alt="">
    </div>
    <div class="logo-container">
        <p>{{this.realization}}</p>
        <a href="https://ipe.df.gov.br" aria-label="Site do Instituto de pesquisa e estatística do distrito federal">
            <img src="{{this.imgLogo}}" alt="Logo do Instituto de pesquisa e estatística do distrito federal">
        </a>
        <ng-template ngFor let-empresa [ngForOf]="empresas">
            <a [href]="empresa.link" attr.aria-label="Site para {{empresa.nome_fantasia}}" *ngIf="empresa.link; else semLink">
                <img [src]="empresa.marca_url" [alt]="'Logo ' + empresa.nome_fantasia" class="logo-empresa">
            </a>
            <ng-template #semLink>
                <img [src]="empresa.marca_url" [alt]="'Logo ' + empresa.nome_fantasia" class="logo-empresa">
            </ng-template>
        </ng-template>
    </div>
</div>