import { Component, Input } from '@angular/core';
import { Text } from 'src/app/models/text.model';
import { EnvconfigService } from 'src/app/services/envconfig.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ipe-icons-grid',
  templateUrl: './icons-grid.component.html',
  styleUrls: ['./icons-grid.component.scss']
})
export class IconsGridComponent {
  @Input() icons: Text[] = [];

  constructor(
    private env: EnvconfigService
  ) { }
  
  baseUrl = this.env.baseUrl;
}
