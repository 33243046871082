import { Component, Input } from '@angular/core';
import { Text } from 'src/app/models/text.model';
import { EnvconfigService } from 'src/app/services/envconfig.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'ipe-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  @Input() title: string = "Produtos IPEDF";
  @Input() brands: Text[] = [];
  @Input() products: Text[] = [];
  titleImg: string = "assets/img-title-footer.svg";

  constructor (
    private env: EnvconfigService,
  ) {}


  baseUrl = this.env.baseUrl;
}
