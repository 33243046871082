<cdk-accordion class="accordion" *ngIf="type == 'default'">
    <cdk-accordion-item
        *ngFor="let item of accordionItems; let i = index"
        class="accordion-item"
        #accordionItem="cdkAccordionItem"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + i"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + i">
        <div class="accordion-item-header" (click)="accordionItem.toggle()">
            {{item.text_name}}
            <span class="accordion-item-description">
                <img src="/assets/seta-accordion-fechado.svg" alt="" class="description-img" [ngClass]="{'is-open': accordionItem.expanded}">
            </span>
        </div>
        <div class="accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + i"
            [attr.aria-labelledby]="'accordion-header-' + i"
            [innerHTML]="item.text">
        </div>
    </cdk-accordion-item>
</cdk-accordion>

<cdk-accordion class="accordion" *ngIf="type == 'reports' || type == 'presentations'">
    <cdk-accordion-item class="accordion-item"
    [ngClass]="type">
        <div class="accordion-item-header header-title">
            {{type == 'presentations' ? 'Apresentações' : 'Relatórios'}}
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item
        *ngFor="let upt of accordionItems; let i = index"
        class="accordion-item"
        #accordionItem="cdkAccordionItem"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + i"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + i">
        <div class="accordion-item-header" (click)="accordionItem.toggle()">
            {{upt.name}}
            <span class="accordion-item-description">
                <span class="observation" *ngIf="upt.observation">{{upt.observation}}</span>
                <img src="/assets/seta-accordion-fechado.svg" alt="" class="description-img" [ngClass]="{'is-open': accordionItem.expanded}">
            </span>
        </div>
        <div class="accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + i"
            [attr.aria-labelledby]="'accordion-header-' + i">
            <ul class="reports-list first">
                <li class="report-item"
                    *ngFor="let report of upt.ras; let index = index"
                    [ngClass]="index % 2 == 0 ? '' : 'white'">
                    <div class="report-item-container">
                        <a href="{{report.report}}" target="_blank" class="report-link">
                            <img src="assets/download-icon.svg" alt="" class="report-icon">
                            <span class="report-name">{{report.name}}</span>
                        </a>
                        <span class="observation" *ngIf="report.observation">{{report.observation}}</span>
                    </div>
                    <ul class="reports-list" *ngIf="report.reports">
                        <li class="report-item" *ngFor="let report of report.reports; let innerIndex = index"
                        [ngClass]="innerIndex % 2 == 0 ? '' : 'white'">
                            <div class="report-item-container">
                                <a href="{{report.report}}" target="_blank" class="report-link">
                                    <img src="assets/download-icon.svg" alt="" class="report-icon">
                                    <span class="report-name">{{report.name}}</span>
                                </a>
                                <span class="observation" *ngIf="report.observation">{{report.observation}}</span>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </cdk-accordion-item>
</cdk-accordion>