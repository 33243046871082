import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ipe-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit{
  constructor( private domSanitizer: DomSanitizer ) { }
  @Input() src: any = '';
  @Input() sanitize: boolean = false;

  ngOnInit() {
    if(!this.sanitize) {
      this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src)
    }
    window.onmessage = (e) => {
      if(e.data && e.data.size) {
        let iframe = document.getElementById('iframe');
        iframe!.style.height = e.data.size;
      }
    }
  }
}
