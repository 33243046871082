<div class="timeline-container">

    <div *ngFor="let item of timelineItems; let i = index;" class="phase-container">
        <div class="title-box {{verifyColor(item.state)}}">
            <p class="phase-text">{{i+1}}ª Fase</p>
            <p class="time-text">({{item.time}})</p>
        </div>
        <div class="img-container">
            <img class="img" src="{{verifyState(item.state)}}" alt="">
        </div>
        <div class="text-container">
            <p class="title">{{item.name}}</p>
            <p class="text-observation" [innerHTML]="item.observation"></p>
        </div>
    </div>

</div>