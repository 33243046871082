import { Component, OnInit  } from '@angular/core';
import { Section } from './models/sections.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Timeline } from './models/timeline.model';
import { Kind } from './models/kind.model';
import { ApiService } from './services/api.service';
import { Map } from './models/map.model';
import { Text } from './models/text.model';
import { EnvconfigService } from './services/envconfig.service';

@Component({
  selector: 'ipe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private env: EnvconfigService,
  ) { }
  
  baseUrl = this.env.baseUrl;

  menuText = 'menu'
  title = 'hotsite-pdad-23';
  buttonText = 'Saiba mais';
  itemsList: Section[] = [];
  linkLogo = 'assets/logo-pdad-ampliada.svg';
  sectionTitle = 'Título da seção';
  imgPath = 'assets/alvo.svg'
  maps: Array<any> = [];
  selectForm = new FormGroup(
    {
      select: new FormControl('')
    }
  );
  showModal = false;
  mapSrc = 'assets/mapa-area-de-cobertura.svg';

  getMaps() {
    // Caso não haja nenhum mapa no array ele faz a requisição
    if (!this.maps.length) {
      this.apiService.getMaps().subscribe((data: Map[]) => {
        this.maps = data;
      });
    }
  }

  toggleModal(map?: string) {
    this.showModal = !this.showModal;

    if (map) {
      this.mapSrc = map;
    }
  }


  timeline: Timeline[] = []
  reports = []
  texts: Kind[] = [];
  footer: Kind[] = [];
  products: Text[] = [];
  brands: Text[] = [];
  iframe: string = '';
  presentationImg: string = '';

  ngOnInit() {
    this.apiService.getTimeline().subscribe((data: Timeline[]) => {
      this.timeline = data;
    });

    this.apiService.getContent().subscribe((data: Kind[]) => {
      this.texts = data.filter((item: Kind) => {
        if (item.slug === 'marcas') {
          this.brands = item.texts;
          return;
        }
        else if (item.slug === 'produtos') {
          this.products = item.texts;
          return;
        }
        else if (item.slug === 'apresentacao') {
          this.presentationImg = item.image.image;
          return;
        }

        else {
          return item;
        }

      })
    });

    this.selectForm.get('select')?.valueChanges.subscribe((value) => {
      if (value) {
        this.toggleModal(value!);
      }
    });

    this.apiService.getLinks().subscribe((data: Section[]) => {
      this.itemsList = data.filter((item: Section) => {
        if (['iframe', 'iframe', 'iframe consulta', 'iframe pesquisador'].includes(item.name.toLowerCase())) {
          this.iframe = item.link;
          return;
        }
        else {
          return item;
        }
      });
    });

    // if(this.timeline[-1].state === 'active') {
    //   //TODO implementar a requisição para o endpoint de relatórios
    // }
  }
}
