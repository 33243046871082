import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from 'src/app/models/empresa';
import { Sigla } from 'src/app/models/sigla';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'ipe-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  @Input() imgLogo: string = 'assets/logo-ipedf.svg';
  @Input() imgPresentation?: string;
  @Input() title: string = 'Pdad ampliada';
  @Input() subtitle: string = 'Pesquisa Distrital por Amostra <br>de Domicílios Ampliada - 2024';
  @Input() realization: string = 'Realização: ';

  constructor(
    private apiService: ApiService,
  ) { }

  empresas: Empresa[] = [];
  siglas: string[] = [];

  ngOnInit(): void {
    this.apiService.getSiglas().subscribe((siglas: Sigla[]) => {
      this.siglas = siglas.map((sigla: Sigla) => sigla.name);

      this.apiService.getEmpresas(this.siglas).subscribe((empresas: Empresa[]) => {
        this.empresas = empresas;
      });
    });

  }

}
