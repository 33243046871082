import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipe-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() type: string = 'default';
  @Input() accordionItems: any[] = [];
}
