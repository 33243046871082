import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';


export interface Configuration {
  apiUrl: string;
  baseUrl: string;
  siteId: string;
  trackerUrl?: string;
  validadorApi?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvconfigService {
  private readonly configUrl = 'assets/config/config.json';
  private configuration$: Observable<Configuration> | undefined;
  public apiUrl: string = '';
  public baseUrl: string = '';
  public validadorApi: string = '';
  public siteId: string = '';
  public trackerUrl: string = 'https://analytics.geact.ipe.df.gov.br/';

  constructor(
    private http: HttpClient,
    private matomoTracker: MatomoTracker
    ) { }

  public load(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<Configuration>(`${this.configUrl}`).pipe(shareReplay(1));
      
      this.configuration$.subscribe((config: Configuration) => {
        this.apiUrl = config.apiUrl;
        this.baseUrl = config.baseUrl;
        this.siteId = config.siteId;
        this.trackerUrl = config.trackerUrl || this.trackerUrl;
        this.validadorApi = config.validadorApi || 'https://validador.ipe.df.gov.br/api';

        this.matomoTracker.setSiteId(this.siteId);
        this.matomoTracker.setTrackerUrl(this.trackerUrl);
      });
      
    }

    return this.configuration$
  }
}
