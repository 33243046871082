import { Component, Input, OnInit } from '@angular/core';
import { Timeline } from '../../models/timeline.model';

@Component({
  selector: 'ipe-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  @Input() timelineItems: Timeline[] = [];

  verifyState(state: string): String {
    if (state == 'enabled') {
      return './assets/timeline-enabled.svg';
    } else if (state == 'active') {
      return './assets/timeline-active.svg';
    } else {
      return './assets/timeline-disabled.svg';
    }
  }

  verifyColor(state: string): String {
    if (state == 'enabled') {
      return 'title-box-enabled';
    } else if (state == 'active') {
      return 'title-box-active';
    } else {
      return 'title-box-disabled';
    }
  }
}
