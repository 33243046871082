import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ipe-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() msgRequired: string = 'Campo não preenchido'
  @Input() msgMaxLength: string = 'O campo deve ter até 150 caracteres'
  @Input() msgMaxLength2: string = 'O campo deve ter até 250 caracteres'
  @Input() invalidEmail: string = 'E-mail inválido'
  @Input() btnText: string = 'ENVIAR MENSAGEM'
  @Input() msgError: string = 'Erro no envio do formulário. Tente novamente'
  @Input() msgAccepted: string = 'Formulário enviado com sucesso!'
  isLoading: boolean = false

  doubtForm!: FormGroup

  constructor(private productService: ApiService, private snackBar: MatSnackBar) { }


  ngOnInit(): void {
    this.doubtForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(150)]),
      email: new FormControl(null, [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(/.+@.+\..+/)]),
      subject: new FormControl(null, [
        Validators.required,
        Validators.maxLength(150)]),
      message: new FormControl(null, [
        Validators.required,
        Validators.maxLength(250)]),
    })
  }

  get name() {
    return this.doubtForm.get('name')!
  }
  get email() {
    return this.doubtForm.get('email')!
  }
  get subject() {
    return this.doubtForm.get('subject')!
  }
  get message() {
    return this.doubtForm.get('message')!
  }

  showMessage(msg: any): void {

    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['msg-valid']
    })
  }

  async onSubmit() {
    if (this.doubtForm.valid) {
      await this.sendForm(this.doubtForm.value, () => {
        this.showMessage(this.msgAccepted)
        this.doubtForm.reset();
      })

    } else {
      this.showMessage(this.msgError)
    }

  }

  sendForm(form: FormGroup, clb: any): void {
    this.isLoading = true
    this.doubtForm.disable()

    this.productService.sendForm(form).subscribe((data) => {
      this.isLoading = false
      this.doubtForm.enable()
      const res: any = data

      if (res.status == "200") {
        clb()
      }

    },
      error => {
        let msg = ''
        for (let key in error.error.message) {
          let value = error.error.message[key];
          msg = value
        }
        this.isLoading = false
        this.doubtForm.enable()
        this.showMessage(msg)
      }
    )

  }
}
