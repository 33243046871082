import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Timeline } from '../models/timeline.model';
import { Kind } from '../models/kind.model';
import { FormGroup } from '@angular/forms';
import { Map } from '../models/map.model';
import { Section } from '../models/sections.model';
import { EnvconfigService } from './envconfig.service';
import { Empresa } from '../models/empresa';
import { Sigla } from '../models/sigla';
import { UptReport } from '../models/upt_report.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private env: EnvconfigService,
  ) { }

  apiUrl: string = this.env.apiUrl;
  validadorApi: string = this.env.validadorApi;
  baseUrl : string = this.env.baseUrl;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),

  };

  getTimeline(): Observable<Timeline[]> {
    return this.http.get<Timeline[]>(this.apiUrl + '/timelines/', this.httpOptions);
  }

  getContent(): Observable<Kind[]> {
    return this.http.get<Kind[]>(this.apiUrl + '/kinds/texts/', this.httpOptions);
  }

  sendForm(form: FormGroup): Observable<FormGroup> {

    return this.http.post<FormGroup>(this.apiUrl + '/send-email/', form, this.httpOptions)
  }

  getMaps(): Observable<Map[]> {
    return this.http.get<Map[]>(this.apiUrl + '/maps/', this.httpOptions);
  }

  getLinks(): Observable<Section[]> {
    return this.http.get<Section[]>(this.apiUrl + '/links/', this.httpOptions);
  }

  getSiglas(): Observable<Sigla[]> {
    return this.http.get<Sigla[]>(this.apiUrl + '/siglas/', this.httpOptions);
  }

  getEmpresas(sigla: string[]): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(this.validadorApi + '/pesquisas/empresas/?siglas=' + sigla.join(","), this.httpOptions);
  }

  getReports() : Observable<UptReport>{
    return this.http.get<UptReport>(this.apiUrl + '/upts_reports/', this.httpOptions);
  }

  getApiUrl(){
    return this.env.baseUrl;
  }

  
  
}
