<div class="container">
    <div id="main-img" style="background-image:url('{{logos.main}}')"></div>
    <!-- <img [src]="logos.main" alt="" id="main-img"> -->
    <div id="imgs-container">
        <div id="first" class="logo" style="background-image:url('{{logos.first}}')"></div>
        <img src="assets/soma.svg" alt="" class="sum-icon">
        <div id="center" class="logo" style="background-image:url('{{logos.second}}')"></div>
        <img src="assets/soma.svg" alt="" class="sum-icon last">
        <div class="logo" style="background-image:url('{{logos.third}}')"></div>
    </div>
</div>