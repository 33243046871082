<form (ngSubmit)="onSubmit()" [formGroup]="doubtForm" #formDir="ngForm" [ngClass]="{'bg-loading': isLoading}">
    <div class="form-group">
        <label for="name">Nome:<span>*</span> </label>
        <input class="inputArea" type="text" placeholder="Seu nome" formControlName="name">
        <div *ngIf="name.invalid && name.untouched === false " class="validation-error">
            <p *ngIf="name.errors?.['required']">{{this.msgRequired}}</p>
            <p *ngIf="name.errors?.['maxlength']">{{this.msgMaxLength}}</p>
        </div>
    </div>
    <div class="form-group">
        <label for="email">Endereço de e-mail:<span>*</span> </label>
        <input class="inputArea" type="text" placeholder="Seu e-mail" formControlName="email">
        <div *ngIf="email.invalid && email.untouched === false" class="validation-error">
            <p *ngIf="email.errors?.['required']">{{this.msgRequired}}</p>
            <p *ngIf="email.errors?.['maxlength']">{{this.msgMaxLength}}</p>
            <p *ngIf="email.errors?.['pattern']">{{this.invalidEmail}}</p>
        </div>
    </div>
    <div class="form-group">
        <label for="subject">Assunto:<span>*</span> </label>
        <input class="inputArea" type="text" placeholder="Assunto" formControlName="subject">
        <div *ngIf="subject.invalid && subject.untouched === false" class="validation-error">
            <p *ngIf="subject.errors?.['required']">{{this.msgRequired}}</p>
            <p *ngIf="subject.errors?.['maxlength']">{{this.msgMaxLength}}</p>
        </div>
    </div>
    <div class="form-group">
        <label for="message">Mensagem:<span>*</span> </label>
        <textarea class="inputArea" placeholder="Digite sua mensagem..." formControlName="message">
        </textarea>
        <div *ngIf="message.invalid && message.untouched === false" class="validation-error">
            <p *ngIf="message.errors?.['required']">{{this.msgRequired}}</p>
            <p *ngIf="message.errors?.['maxlength']">{{this.msgMaxLength2}}</p>
        </div>
    </div>

    <ipe-button type="button" class="btn-form" [title]="btnText"></ipe-button>
</form>