import { Component, Input, OnInit } from '@angular/core';
import { Section } from 'src/app/models/sections.model';

@Component({
  selector: 'ipe-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() logo!: string;
  @Input() items!: Section[];
  @Input() menu!: string;
  @Input() showConsultaBtn:boolean = false;

  ngOnInit(): void {
    const btnMobile = document.getElementById('btn-mobile');

    function toggleMenu() {
      const nav = document.getElementById('nav');
      nav?.classList.toggle('active');
    }

    btnMobile?.addEventListener('click', toggleMenu)

  }

  removeMenuList() {
    const nav = document.getElementById('nav');
    nav?.classList.remove('active');
  }

}
