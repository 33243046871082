<section id="presentationsSection" *ngFor="let presentation of presentations">
    <h2>{{presentation.name}}</h2>
    <div class="presentation-container">
        <div class="presentation-card" *ngFor="let ra of presentation.ras">
            <a href="{{apiUrl +  ra.report}}"  [attr.aria-label]="'Relatório ' + ra.name" target="_blank">{{ra.name}}</a>
        </div>

    </div>
</section>

<section id="reportsSection">
    <ng-container *ngFor="let text of reportTexts">
        <h2 *ngIf="text.text_name == 'Título'">{{text.text}}</h2>   
        <p *ngIf="text.text_name == 'Subtítulo'">{{text.text}}</p>
    </ng-container>
    

    <div class="reports-cards">
        <div class="reports-container" *ngFor="let report of reports">
            <h3>{{report.name}}</h3>
            <ul class="ras-list">
                <ng-container *ngFor="let ra of report.ras">
                    <li> <a href="{{apiUrl + ra.report}}" [attr.aria-label]="'Relatório ' + ra.name" target="_blank">{{ra.name}}</a></li>
                    <ul *ngIf="ra.reports!.length > 0" class="sub-ras-list">
                        <ng-container *ngFor="let subRa of ra.reports">
                            <li><a href="{{apiUrl + subRa.report}}" [attr.aria-label]="'Relatório ' + subRa.name" target="_blank">{{subRa.name}}</a></li>
                        </ng-container>
                    </ul>
                </ng-container>
            </ul>
        </div>
    </div>
</section>