<header class="header">
    <div>
        <img src="{{logo}}" alt="" class="logo">
    </div>
    <div class="buttons-container">
        <a class="btn-valida-cpf" href="#validacao-de-pesquisador" *ngIf="showConsultaBtn">
            <img src="assets/botao-valida-cpf-header.svg" alt="">
            <p>Validação de Pesquisador</p>
        </a>

        <nav id="nav">
            <button id="btn-mobile">
                <p>{{menu}}</p>
                <img src="assets/hamburguer.svg" alt="" class="btn-img">
            </button>
            <div class="menu-container">
                <ul id="menu">
                    <li *ngFor="let item of items" class="menu-item">
                        <a (click)="removeMenuList()" href="{{item.link}}">{{item.name}}</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>