import { Component, Input } from '@angular/core';
import { Text } from 'src/app/models/text.model';
import { EnvconfigService } from 'src/app/services/envconfig.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {
  @Input() products: Text[] = [];

  constructor (
    private env: EnvconfigService,
  ) {}

  baseUrl = this.env.baseUrl;

  showMore:boolean = false;
  showMoreString:string = "hide";

  toggle(){
    this.showMore = !this.showMore;
    this.showMoreString = this.showMore ? "show" : "hide";
  }
}
