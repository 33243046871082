import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { SectionComponent } from './components/section/section.component';
import { ProductComponent } from './components/footer/product/product.component';
import { IconsGridComponent } from './components/icons-grid/icons-grid.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { TimelineComponent } from './components/timeline/timeline.component';
import { InfographicComponent } from './components/infographic/infographic.component';
import { HttpClientModule } from '@angular/common/http';
import { FormComponent } from './components/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IframeComponent } from './components/iframe/iframe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PresentationComponent } from './components/presentation/presentation.component';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { EnvconfigService } from './services/envconfig.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    SectionComponent,
    ProductComponent,
    IconsGridComponent,
    AccordionComponent,
    TimelineComponent,
    InfographicComponent,
    FormComponent,
    IframeComponent,
    PresentationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CdkAccordionModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://analytics.geact.ipe.df.gov.br/', siteId: '' }),
    NgxMatomoRouterModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (envConfigService: EnvconfigService) => () => envConfigService.load().toPromise(),
    deps: [EnvconfigService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
