import { Component } from '@angular/core';

@Component({
  selector: 'ipe-infographic',
  templateUrl: './infographic.component.html',
  styleUrls: ['./infographic.component.scss']
})
export class InfographicComponent {
  logos = {
    main : 'assets/logo-pdad-ampliada-2024.svg',
    first : 'assets/logo-pdad-sem-ano.svg',
    second : 'assets/logo-pdad-rural.svg',
    third : 'assets/logo-pmad.svg', 
    };
}
