<div class="container">
    <span id="title">
        <img src="{{titleImg}}" alt="" id="title-img">
        {{title}}
    </span>
    <app-product [products]="products"></app-product>
</div>
<div id="brands">
    <div class="container">
        <a href="{{b.text}}" class="brand-link"
        *ngFor="let b of brands">
            <img src="{{baseUrl + b.image.image}}" alt="{{b.text_name}}" class="brand-img">
        </a>
    </div>
</div>