<ipe-header [items]="itemsList" [logo]="linkLogo" [menu]="menuText" [showConsultaBtn]="iframe !== ''"></ipe-header>
<ipe-presentation [imgPresentation]=""></ipe-presentation>
<ipe-section *ngFor="let section of texts" [title]="section.name" [id]="section.slug"
  [img]="section.image ? baseUrl + section.image.image : ''">
  <ng-container *ngIf="section.slug != 'sessao-de-faq' && section.slug != 'pdad-ampliada-em-numeros' && section.slug != 'resultados'">
    <p class="section-text" *ngFor="let text of section.texts" [innerHTML]="text.text">
    </p>
  </ng-container>
  
  <ipe-reports *ngIf="section.slug == 'resultados'" [reportTexts]="section.texts"></ipe-reports>
  <ipe-infographic *ngIf="section.slug == 'o-que-e-a-pdad-ampliada'"></ipe-infographic>
  <div *ngIf="section.slug == 'area-de-cobertura'">
    <div id="covered-area">
      <div class="select-container">
        <label for="ras-">Selecione o município ou RA</label>
        <div class="select">
          <form id="select-form" [formGroup]="selectForm">
            <select name="" id="ras-select" (click)="getMaps()" formControlName="select">
              <option value="">Selecione a RA</option>
              <option value="{{map.url}}" *ngFor="let map of maps">{{map.name}}</option>
            </select>
          </form>
        </div>
      </div>
      <img src="assets/mapa-area-de-cobertura.svg" alt="" id="covered-area-map">
    </div>
    <div id="map-modal-container" (click)="toggleModal()" *ngIf="showModal" [ngClass]="showModal ? 'show' : 'hide'">
      <p href="" (click)="toggleModal()">X</p>
      <img [src]="mapSrc" alt="" id="map">
    </div>
  </div>
  <ipe-timeline *ngIf="section.slug == 'objetivo-da-pesquisa'" [timelineItems]="timeline"></ipe-timeline>
  <ipe-iframe [src]="iframe" *ngIf="section.slug == 'validacao-de-pesquisador' && iframe !== ''" id="iframe-consulta"></ipe-iframe>
  <ipe-accordion [accordionItems]="section.texts" *ngIf="section.slug == 'sessao-de-faq'"></ipe-accordion>
  <ipe-icons-grid *ngIf="section.slug == 'pdad-ampliada-em-numeros'" [icons]="section.texts"></ipe-icons-grid>
  <ipe-form *ngIf="section.slug == 'canais-de-contato'"></ipe-form>
</ipe-section>
<ipe-footer [products]="products" [brands]="brands"></ipe-footer>
